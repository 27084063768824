import CloseIcon from '@mui/icons-material/Close';
import { Box, BoxProps, Dialog, IconButton, Stack } from '@mui/material';
import React from 'react';

import { useDeviceType } from '@app/hooks/useBrowserHooks';

interface EnlargedImageProps extends BoxProps {
  height?: string;
  objectFit?: string;
  src: string;
  title: string;
  width?: string;
}

/**
 * クリックすると拡大画像が表示される画像を表示するコンポーネント。
 * @param height - 画像の高さ
 * @param objectFit - 画像の表示方法
 * @param src - 画像のURL
 * @param title - 画像のタイトル
 * @param width - 画像の幅
 * @returns クリックすると拡大画像が表示される画像
 */
export const EnlargedImage: React.FC<EnlargedImageProps> = ({
  src,
  title,
  height = '200px',
  width = '100%',
  objectFit = 'cover',
  ...props
}: EnlargedImageProps) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const { isMobile, isDesktop } = useDeviceType();
  return (
    <>
      <Box
        onClick={() => setIsOpen(true)}
        component="img"
        src={src}
        alt={title}
        sx={{
          borderRadius: 1,
          cursor: 'pointer',
          height,
          objectFit,
          width,
        }}
        {...props}
      />
      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        fullScreen
        fullWidth
        PaperProps={{
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
          },
        }}
      >
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            height: '100vh',
            justifyContent: 'center',
            width: '100vw',
          }}
        >
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            position="relative"
            bgcolor="#fff"
            width={isDesktop ? '80%' : '95%'}
            paddingY={isDesktop ? '' : '64px'}
            height={isDesktop ? '90%' : 'fit-content'}
          >
            <IconButton
              onClick={() => setIsOpen(false)}
              sx={{
                position: 'absolute',
                right: 0,
                top: 0,
              }}
            >
              <CloseIcon fontSize="large" />
            </IconButton>
            <Box
              component="img"
              src={src}
              alt={title}
              width="100%"
              height="100%"
              sx={{
                objectFit: 'contain',
                padding: isMobile ? '16px' : '64px',
              }}
            />
          </Stack>
        </Box>
      </Dialog>
    </>
  );
};
