import {
  CategoryType,
  ProductEventTitle,
  ProductLandRightType,
} from '@app/types/catalog';
import { Common } from '@app/types/common';
import { InquiryStepTwoQuestion, FormType } from '@app/types/order';

export const TERMS_CUSTOMER_LINK = 'https://pro-cloud.jp/terms_customer';
export const PRIVACY_POLICY_LINK = 'https://pro-cloud.jp/privacy';

export const PREFECTURE_OPTIONS = [
  '北海道',
  '青森県',
  '岩手県',
  '宮城県',
  '秋田県',
  '山形県',
  '福島県',
  '茨城県',
  '栃木県',
  '群馬県',
  '埼玉県',
  '千葉県',
  '東京都',
  '神奈川県',
  '新潟県',
  '富山県',
  '石川県',
  '福井県',
  '山梨県',
  '長野県',
  '岐阜県',
  '静岡県',
  '愛知県',
  '三重県',
  '滋賀県',
  '京都府',
  '大阪府',
  '兵庫県',
  '奈良県',
  '和歌山県',
  '鳥取県',
  '島根県',
  '岡山県',
  '広島県',
  '山口県',
  '徳島県',
  '香川県',
  '愛媛県',
  '高知県',
  '福岡県',
  '佐賀県',
  '長崎県',
  '熊本県',
  '大分県',
  '宮崎県',
  '鹿児島県',
  '沖縄県',
] as const;

export const BOTTOM_MENU_ITEMS = [
  {
    key: 3,
    target: '',
    text: 'Home',
    to: '/',
  },
  {
    key: 4,
    target: '_blank',
    text: 'Company',
    to: 'https://pro-cloud.jp/company',
  },
  // MEMO: 今後追加予定
  // {
  //   key: 5,
  //   target: '_blank',
  //   text: 'Blog',
  //   to: '#',
  // },
  // {
  //   key: 6,
  //   target: '_blank',
  //   text: 'Instagram',
  //   to: '#',
  // },
  // {
  //   key: 7,
  //   target: '_blank',
  //   text: 'X（旧Twitter）',
  //   to: '#',
  // },
];

export const SIDE_BAR_JOB_MENU_ITEMS = [
  { isDisabled: true, key: 1, path: '/', text: '仕事一覧' },
  { isDisabled: true, key: 2, path: '/chats', text: '通知一覧' },
];

export const SIDE_BAR_SYSTEM_MENU_ITEMS_NO_LOGIN = [
  {
    isDisabled: false,
    key: 1,
    path: 'https://med-pro.jp/media.dr/clp/hp/',
    target: '_blank',
    text: '掲載希望の方へ',
  },
];

export const SIDE_BAR_SYSTEM_MENU_ITEMS = [
  {
    isDisabled: false,
    key: 1,
    path: 'https://med-pro.jp/lp.dr/',
    target: '_blank',
    text: 'Pro Doctorsについて',
  },
  {
    isDisabled: false,
    key: 2,
    path: 'https://docs.google.com/forms/d/e/1FAIpQLSfStJDKdx1mouZ5ncVftqaS4gfBbUvOtahJpbBoCfEZAYwB5Q/viewform?usp=send_form',
    target: '_blank',
    text: 'お問い合わせ',
  },
];

/*
  localStorage, sessionStorage を使う場合はここに key を追加
  重複しないように一元管理
*/
export const STORAGE_KEYS = {
  ACCESS_TOKEN: 'accessToken',
  AFTER_LOGIN_ROUTE: 'afterLoginRoute',
  FINGERPRINT: 'fingerprint', // 予約
  REFRESH_TOKEN: 'refreshToken', // 予約
  USER_ID: 'userId',
};

export const ERROR_MESSAGE = {
  EMAIL_NOT_FOUND:
    '入力されたメールアドレスの会員情報が見つかりません。間違ったメールアドレスを入力されていないかご確認ください。',
  INVALID_USER_ID: 'ログアウトします',
  INVALID_USER_TYPE: 'ProDoctorsの医療機関側のURLで再度お試しください。',
  TOKEN_EXPIRED: '認証の有効期限が切れました。再ログインしてください。',
};

export const occupationOptions = [
  '管理的職業',
  '研究・技術の職業',
  '法務・経営・文化芸術等の専門的職業',
  '医療・看護・保健の職業',
  '保育・教育の職業',
  '事務的職業',
  '販売・営業の職業',
  '福祉・介護の職業',
  'サービスの職業',
  '警備・保安の職業',
  '農林漁業の職業',
  '製造・修理・塗装・製図等の職業',
  '配送・輸送・機械運転の職業',
  '建設・土木・電気工事の職業',
  '運搬・清掃・包装・選別等の職業',
];

export const SEARCH_OPTIONS = [
  { label: '住宅展示場を探す', value: CategoryType.EXHIBITION },
  { label: '分譲を探す', value: CategoryType.PROPERTY },
];

export const ACCESS_OPTIONS = [
  { label: '1分以内', value: '1' },
  { label: '3分以内', value: '3' },
  { label: '5分以内', value: '5' },
  { label: '7分以内', value: '7' },
  { label: '10分以内', value: '10' },
  { label: '15分以内', value: '15' },
  { label: '20分以内', value: '20' },
];

export const InquiryStepSecondQuestions: InquiryStepTwoQuestion[] = [
  {
    formType: FormType.CHECKBOX,
    otherFlg: true,
    required: false,
    selections: ['配偶者', 'お子様'],
    subText: 'その他',
    title: '家族・世帯構成',
  },
  {
    formType: FormType.RADIO,
    otherFlg: false,
    required: true,
    selections: ['男性', '女性', 'その他'],
    subText: '',
    title: '性別',
  },
  {
    formType: FormType.PULLDOWN,
    otherFlg: false,
    required: false,
    selections: [
      '0 ~ 300万円',
      '301万円 ~ 500万円',
      '501万円 ~ 700万円',
      '701万円 ~ 900万円',
      '901万円 ~ 1100万円',
      '1100万円 ~ ',
    ],
    subText: '',
    title: '世帯年収',
  },
  {
    formType: FormType.TEXT,
    otherFlg: false,
    required: false,
    selections: [],
    subText: '',
    title: '建築予定地',
  },
];

export const CompanyInquiryStepSecondQuestions: InquiryStepTwoQuestion[] = [
  {
    formType: FormType.CHECKBOX,
    otherFlg: true,
    otherFlg2: true,
    required: false,
    selections: ['配偶者', 'お子様'],
    subText: 'その他',
    subText2: '2',
    subUnit2: '人',
    title: '家族・世帯構成',
  },
  {
    formType: FormType.RADIO,
    otherFlg: false,
    required: false,
    selections: ['男性', '女性', 'その他'],
    subText: '',
    title: '性別',
  },
  {
    formType: FormType.TEXT,
    otherFlg: false,
    required: false,
    selections: [],
    subText: '',
    title: '建築予定地',
  },
];

export const ROOM_TYPE_OPTIONS = [
  { label: '1K/DK/LDK', value: 1 },
  { label: '2K/DK/LDK', value: 2 },
  { label: '3K/DK/LDK', value: 3 },
  { label: '4K/DK/LDK', value: 4 },
  { label: '5K/DK/LDK以上', value: 5 },
];

export const ORGANIZATION_STRUCTURE_TYPE = {
  CHILDREN: 'children',
  PARENT: 'parent',
};

export const propertyLimitationValues: { [key: string]: string } = {
  '001': '文化財保護法',
  '002': '古都保存法',
  '003': '景観法',
  '004': '密集市街地整備法',
  '005': '航空法',
  '006': '河川法',
  '007': '砂防法',
  '008': '農地法届出要',
  '009': '安全条例',
  '010': '宅地造成工事規制区域',
  '011': '急傾斜地崩壊危険区域',
  '012': '高度地区',
  '101': '高度利用地区',
  '102': '中高層階住居專用地区',
  '103': '高層住居誘導地区',
  '104': '防火地域',
  '105': '準防火地域',
  '106': '風致地区',
  '107': '景観地区',
  '108': '準景観地区',
  '109': '観光団地',
  '110': '歷史風土保存地区',
  '111': '伝統的建造物群保存地区',
  '112': '特定街区',
  '201': '特別用途制限地域',
  '202': '文教地区',
  '203': '都市再生特別地区',
  '204': '特別綠地保全地区',
  '205': '高さ最高限度有',
  '206': '高さ最低限度有',
  '207': '建べい率最低限度有',
  '208': '容積率最低限度有',
  '209': '敷地面積最高限度有',
  '210': '敷地面積最低限度有',
  '211': '建物面積最高限度有',
  '212': '建物面積最低限度有',
};

export const propertyOtherLimitationValues: { [key: string]: string } = {
  '001': 'ー部都市計画道路',
  '002': '一部協定通路',
  '003': '日影制限有',
  '004': '隅切り有',
  '005': '接道と段差有',
  '006': '敷地内段差有',
  '007': '壁面後退有',
  '008': '建築協定有',
  '009': '産上につき建築制限有',
  '010': '崖下につき建築制限有',
  '011': '不整形地',
};

export const EVENT_NAME_OPTIONS = [
  { label: '現地見学会', value: ProductEventTitle.LOCAL_TOUR },
  { label: '現地案内会', value: ProductEventTitle.LOCAL_GUIDE },
  { label: '現地販売会', value: ProductEventTitle.LOCAL_SALE },
  { label: 'オープンハウス', value: ProductEventTitle.OPEN_HOUSE },
  { label: 'モデルハウス', value: ProductEventTitle.MODEL_HOUSE },
  { label: 'オープンルーム', value: ProductEventTitle.OPEN_ROOM },
  { label: 'モデルルーム', value: ProductEventTitle.MODEL_ROOM },
  { label: '未選択', value: Common.EMPTY },
];

export const LAND_RIGHT_TYPE_OPTIONS = [
  { label: '所有権', value: ProductLandRightType.OWNERSHIP },
  { label: '旧法地上', value: ProductLandRightType.OLD_LAW_GROUND },
  { label: '旧法賃借', value: ProductLandRightType.OLD_LAW_LEASE },
  { label: '普通地上', value: ProductLandRightType.REGULAR_GROUND },
  { label: '定期地上', value: ProductLandRightType.FIXED_GROUND },
  { label: '普通賃借', value: ProductLandRightType.REGULAR_LEASE },
  { label: '定期賃借', value: ProductLandRightType.FIXED_LEASE },
];

// 分譲 - 建築許可理由
export const PROPERTY_BUILDING_PERMIT_REASON_TYPE_VALUES: {
  [key: string]: string;
} = {
  '1': '開発許可等による分譲地',
  '2': '都市計画法執行令36条1項3号口に該当',
  '3': '調整区域につき建築許可要',
  '4': '調整区域につき建築許可要。建築主の許可要件あり',
};

export enum Environment {
  DEVELOPMENT = 'development',
  PRODUCTION = 'production',
}

/**
 * 予約当日を含む直近から予約不可な日数
 */
export const RESERVATION_UNAVAILABLE_DAYS = 4;

export const GA_CUSTOM_EVENT = {
  COMPANY_SEARCH_EXHIBITION: 'companySearchExhibition',
  COMPANY_SEARCH_PROPERTIES: 'companySearchProperties',
  COMPANY_VIEW_EXHIBITION: 'companyViewExhibition',
  COMPANY_VIEW_PROPERTIES: 'companyViewProperties',
  EXHIBITION_DOCUMENT_REQUEST: 'exhibitionDocumentRequest',
  EXHIBITION_TOUR_RESERVATION: 'exhibitionTourReservation',
  INQUIRY_DOCUMENT_REQUEST: 'documentRequestPageView',
  INQUIRY_STEP1: 'inquiryStep1',
  INQUIRY_STEP2: 'inquiryStep2',
  INQUIRY_SUBMIT_COMPLETED: 'formSubmissionComplete',
  INQUIRY_TOUR_RESERVATION: 'tourReservationPageView',
  PROPERTIES_DOCUMENT_REQUEST: 'propertiesDocumentRequest',
  PROPERTIES_TOUR_RESERVATION: 'propertiesTourReservation',
  SEARCH_RESULT_SEARCH: 'searchResultSearch',
  SEARCH_RESULT_VIEW_COMPANY: 'searchResultViewCompany',
  SEARCH_RESULT_VIEW_EXHIBITION: 'searchResultViewExhibition',
  SEARCH_RESULT_VIEW_PROPERTIES: 'searchResultViewProperties',
  TOP_SEARCH: 'topSearch',
};

export const GA_CUSTOM_EVENT_SUFFIX = {
  CENTER: 'center',
  RIGHT: 'right',
};
