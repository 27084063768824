import {
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';
import React from 'react';

import { TextPretty } from '@app/components/Shared/TextPretty';
import { theme } from '@app/theme';
import { Organization } from '@app/types/organization';

interface CompanyInfoProps {
  organization: Organization;
}

const TableCellWithStyle = ({ children }: { children: React.ReactNode }) => (
  <TableCell
    sx={{
      backgroundColor: theme.palette.neutral.sky,
      fontWeight: 'bold',
      width: '260px',
    }}
  >
    {children}
  </TableCell>
);

export function CompanyInfo({ organization }: CompanyInfoProps) {
  return (
    <div>
      <Typography variant="h6" fontWeight={600} sx={{ mb: 3 }}>
        会社情報
      </Typography>
      <TableContainer
        component={Paper}
        sx={{ border: '1px solid #e0e0e0', borderRadius: '8px' }}
      >
        <Table>
          <TableBody>
            <TableRow>
              <TableCellWithStyle>会社名</TableCellWithStyle>
              <TableCell>
                <TextPretty
                  text={organization.customFields?.companyName}
                  color={'#222222'}
                  formType="lightBase"
                />
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCellWithStyle>所在地</TableCellWithStyle>
              <TableCell>
                <TextPretty
                  text={organization.customFields?.address}
                  color={'#222222'}
                  formType="lightBase"
                />
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCellWithStyle>設立</TableCellWithStyle>
              <TableCell>
                <TextPretty
                  text={organization.customFields?.establishment}
                  color={'#222222'}
                  formType="lightBase"
                />
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCellWithStyle>問い合わせ</TableCellWithStyle>
              <TableCell>
                <TextPretty
                  text={organization.phoneNumber}
                  formType="lightBase"
                />
                <TextPretty
                  text={organization.customFields?.phoneNumberSub}
                  formType="lightXs"
                  color={theme.palette.text.secondary}
                />
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCellWithStyle>定休日</TableCellWithStyle>
              <TableCell>
                <TextPretty
                  text={organization.customFields?.closingDay}
                  formType="lightBase"
                />
                <TextPretty
                  text={organization.customFields?.closingDaySub}
                  color={theme.palette.text.secondary}
                  formType="lightXs"
                />
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCellWithStyle>資本金</TableCellWithStyle>
              <TableCell>
                <TextPretty
                  text={organization.customFields?.capital}
                  formType="lightBase"
                />
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCellWithStyle>従業員数</TableCellWithStyle>
              <TableCell>
                <TextPretty
                  text={
                    organization.customFields?.size
                      ? `${organization.customFields.size}人`
                      : ''
                  }
                  formType="lightBase"
                />
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCellWithStyle>施工エリア</TableCellWithStyle>
              <TableCell>
                <TextPretty
                  text={organization.customFields?.constructionArea}
                  formType="lightBase"
                />
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCellWithStyle>施工実績</TableCellWithStyle>
              <TableCell>
                <TextPretty
                  text={organization.customFields?.constructionResult}
                  formType="lightBase"
                />
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCellWithStyle>対応可能工法</TableCellWithStyle>
              <TableCell>
                <TextPretty
                  text={organization.customFields?.compatibleConstructionMethod}
                  formType="lightBase"
                />
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCellWithStyle>アフター・補償</TableCellWithStyle>
              <TableCell>
                <TextPretty
                  text={organization.customFields?.compensate}
                  formType="lightBase"
                />
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCellWithStyle>ホームページ</TableCellWithStyle>
              <TableCell>
                <TextPretty text={organization.url} formType="lightBase" />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
