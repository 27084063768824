import { Box, Grid, Typography, Divider } from '@mui/material';
import React from 'react';

import { EnlargedImage } from '@app/components/Shared/EnlargedImage';
import { theme } from '@app/theme';
import { ProductVariant } from '@app/types/catalog';

interface ExhibitionFloorPlanProps {
  variants: ProductVariant[];
}

export function ExhibitionFloorPlan({ variants }: ExhibitionFloorPlanProps) {
  return (
    <Box sx={{ mt: 3 }}>
      <Typography variant="h6" fontWeight={600}>
        間取り、モデルハウス情報
      </Typography>
      {variants.map((variant, index) => (
        <Grid container spacing={2} key={index} sx={{ mb: 4, mt: 4 }}>
          {variant.images?.[0]?.url && (
            <Grid item xs={12} md={4}>
              <EnlargedImage
                src={variant.images[0].url}
                title={variant.title}
              />
            </Grid>
          )}
          <Grid item xs={12} md={8}>
            <Box
              sx={{
                background:
                  'linear-gradient(to right, #e0f7fa, #e0f2f1, #e0f7fa)',
                borderRadius: '16px',
                display: 'inline-block',
                mb: 1,
                px: 2,
                py: 0.5,
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  color: theme.palette.primary.dark,
                  fontWeight: 'bold',
                }}
              >
                Point.{index + 1}
              </Typography>
            </Box>
            <Typography variant="h6" fontWeight="bold" sx={{ mb: 1 }}>
              {variant.title}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {variant.description}
            </Typography>
          </Grid>
        </Grid>
      ))}
      <Divider sx={{ my: 8 }} />
    </Box>
  );
}
