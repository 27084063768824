import { Box, Button, Grid, Typography } from '@mui/material';
import { ReactElement, useCallback, useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';

import { getProduct } from '@app/adapter/catalog-service';
import { ProgressStepper } from '@app/components/Order/ProgressStepper';
import { LoadingSpinner } from '@app/components/Shared/LoadingSpinner';
import { useSetSnackbar } from '@app/hooks/useSetSnackbar';
import { GA_CUSTOM_EVENT } from '@app/static/constants';
import { theme } from '@app/theme';
import { Product } from '@app/types/catalog';
import { isError } from '@app/utils/error';

export function Completed(): ReactElement {
  const navigate = useNavigate();
  const setSnackbar = useSetSnackbar();
  const [product, setProduct] = useState<Product | null>(null);
  const [searchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(false);
  const productId = searchParams.get('productId');
  const fetchProduct = useCallback(async () => {
    if (!productId) return;
    try {
      setIsLoading(true);
      const result = await getProduct(productId);
      setProduct(result.data);
    } catch (e) {
      if (isError(e)) {
        setSnackbar(true, e.message);
      }
    } finally {
      setIsLoading(false);
    }
  }, [productId, setSnackbar]);

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: GA_CUSTOM_EVENT.INQUIRY_SUBMIT_COMPLETED,
      },
    });
    void fetchProduct();
  }, [fetchProduct]);

  const organization =
    typeof product?.organization !== 'string' ? product?.organization : null;

  const handleGoToTopPage = () => {
    navigate('/');
  };

  const handleRegister = () => {
    navigate('/register');
  };
  return (
    <Box
      sx={{
        margin: '0 auto',
        maxWidth: '800px',
        mt: 1,
        padding: 3,
      }}
    >
      <Grid
        container
        direction="column"
        alignItems="center"
        textAlign="center"
        sx={{ mt: 10 }}
      >
        <Typography variant="h6" fontWeight="bold">
          資料請求が完了しました
        </Typography>
        <Grid sx={{ my: 3 }}>
          <Typography color="textSecondary">
            資料を請求いただきありがとうございました。
          </Typography>
          <Typography color="textSecondary">
            入力いただいたメールアドレス宛に資料を掲載元よりお送りします
          </Typography>
          <Typography color="textSecondary">
            ２週間お待ちいただいても資料が届かない場合は、下記フォームよりお問い合わせください。
          </Typography>
          <Typography color="textSecondary">
            <Link to="https://pro-cloud.jp/contact" target="_blank">
              お問い合わせ
            </Link>
          </Typography>
        </Grid>
      </Grid>

      <ProgressStepper activeStep={4} />
      {isLoading ? (
        <LoadingSpinner sx={{ mb: 4 }} />
      ) : (
        <Box
          sx={{
            border: `1px solid ${theme.palette.neutral.silver}`,
            borderRadius: 1,
            color: theme.palette.text.primary,
            mb: 10,
            padding: 3,
            width: '100%',
          }}
        >
          <Typography variant="body1" fontWeight={'bold'} sx={{ mb: 3 }}>
            お問い合わせ先
          </Typography>
          <Typography>{organization?.name || '-'}</Typography>
          {product?.customFields.contactAddress && (
            <Typography>
              問い合わせ先名：{product.customFields.contactAddress}
            </Typography>
          )}
          {product?.customFields.contactPhoneNumber && (
            <Typography>
              問い合わせ先電話番号：{product.customFields.contactPhoneNumber}
            </Typography>
          )}
          {product?.customFields.contactAdditional && (
            <Typography>
              問い合わせ先補足：{product.customFields.contactAdditional}
            </Typography>
          )}
          <Typography variant="body1">
            ※休業日にいただいたお問い合わせにつきましては、翌営業日でのご対応とさせていただきます。
          </Typography>
        </Box>
      )}

      <Box
        sx={{
          backgroundColor: theme.palette.neutral.sky,
          borderRadius: 2,
          margin: '0 auto',
          maxWidth: '800px',
          mb: 5,
          padding: 3,
          textAlign: 'center',
        }}
      >
        <Typography variant="h6" fontWeight="bold" sx={{ mb: 2 }}>
          会員登録
        </Typography>
        <Typography variant="body1" sx={{ mb: 5 }}>
          会員登録をすると、次の問い合わせの際にお客様情報の入力を省略できるようになります
        </Typography>
        <Button
          variant="contained"
          color="primary"
          sx={{ height: { md: 60, xs: 48 }, p: 2, width: '100%' }}
          onClick={handleRegister}
        >
          会員登録をする
        </Button>
      </Box>

      <Button
        variant="outlined"
        color="secondary"
        onClick={handleGoToTopPage}
        sx={{
          height: { md: 60, xs: 48 },
          my: { md: 7, xs: 4 },
          p: 2,
          width: '100%',
        }}
      >
        トップページへ
      </Button>
    </Box>
  );
}
