import {
  Box,
  Typography,
  FormControl,
  Button,
  Stack,
  TextField,
  FormHelperText,
} from '@mui/material';
import React, { ReactElement } from 'react';
import TagManager from 'react-gtm-module';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useRecoilState } from 'recoil';

import { FormLabelComponent } from '@app/components/Order/FormLabelComponents';
import { LocationsWithPostalCode } from '@app/components/Shared/LocationsWithPostalCode';
import { InquiryStepOneState } from '@app/domain/order';
import { useDeviceType } from '@app/hooks/useBrowserHooks';
import {
  InquiryStepOneForm,
  defaultInquiryStepOneFormData,
} from '@app/schemas/order';
import { GA_CUSTOM_EVENT } from '@app/static/constants';
import { theme } from '@app/theme';
import { InquiryStepOneFormData } from '@app/types/order';
import { generateEventName } from '@app/utils/event';
import { OrderStep } from '@app/views/Company/Register';

interface ExhibitionStep1Props {
  defaultValues?: InquiryStepOneFormData;
  goToStep2: (value: React.SetStateAction<OrderStep>) => void;
  onSubmit: (data: InquiryStepOneFormData) => void;
}

export function CompanyInquiryStep1({
  onSubmit,
  defaultValues = defaultInquiryStepOneFormData,
  goToStep2,
}: ExhibitionStep1Props): ReactElement {
  const [stepData, setStepData] = useRecoilState(InquiryStepOneState);
  const { isMobile } = useDeviceType();
  const initialData: InquiryStepOneFormData = {
    ...defaultValues,
    ...stepData,
  };

  const form = useForm<InquiryStepOneFormData>({
    defaultValues: initialData,
    mode: 'onChange',
    resolver: InquiryStepOneForm.resolver,
  });

  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = form;

  const handleStep1Submit = (data: InquiryStepOneFormData) => {
    setStepData((prevData) => ({
      ...prevData,
      ...data,
    }));
    onSubmit(data);
    TagManager.dataLayer({
      dataLayer: {
        event: generateEventName(GA_CUSTOM_EVENT.INQUIRY_STEP1, isMobile),
      },
    });
  };

  return (
    <Stack
      sx={{
        alignItems: 'center',
        pb: 4,
        width: '100%',
      }}
      spacing={4}
    >
      <Box
        sx={{
          maxWidth: '800px',
          position: 'relative',
          width: '100%',
        }}
      >
        <FormProvider<InquiryStepOneFormData> {...form}>
          <Stack
            component="form"
            onSubmit={handleSubmit(handleStep1Submit)}
            sx={{
              backgroundColor: theme.palette.neutral.white,
              maxWidth: '800px',
              mb: { md: 20, xs: 10 },
              padding: { md: '40px' },
              width: '100%',
            }}
          >
            <Typography variant="body1" fontWeight="bold" sx={{ mb: 4 }}>
              2. あなたについて教えてください
            </Typography>
            <FormControl fullWidth variant="outlined" sx={{ mb: 4 }}>
              {FormLabelComponent('名前', true)}
              <Controller
                name="customer.name"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    error={!!error}
                    helperText={error?.message}
                    placeholder="山田太郎"
                  />
                )}
              />
            </FormControl>

            <FormControl fullWidth variant="outlined" sx={{ mb: 4 }}>
              {FormLabelComponent('フリガナ', true)}
              <Controller
                name="customer.nameKana"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    error={!!error}
                    helperText={error?.message}
                    placeholder="ヤマダタロウ"
                  />
                )}
              />
            </FormControl>

            <FormControl fullWidth variant="outlined" sx={{ mb: 4 }}>
              {FormLabelComponent('年齢', true)}
              <Stack>
                <Controller
                  name="customer.age"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <>
                      <Stack direction="row" alignItems="center">
                        <TextField
                          {...field}
                          type="number"
                          error={!!error}
                          placeholder="30"
                          inputProps={{ min: 0 }}
                          sx={{ width: '100px' }}
                        />
                        <Typography sx={{ ml: 1 }}>歳</Typography>
                      </Stack>
                      {error && (
                        <FormHelperText error>{error.message}</FormHelperText>
                      )}
                    </>
                  )}
                />
              </Stack>
            </FormControl>

            <FormControl fullWidth variant="outlined" sx={{ mb: 4 }}>
              {FormLabelComponent('電話番号', true)}
              <Controller
                name="customer.phone"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    type="text"
                    error={!!error}
                    helperText={error?.message}
                    placeholder="09012345678"
                  />
                )}
              />
            </FormControl>

            <FormControl fullWidth variant="outlined" sx={{ mb: 4 }}>
              {FormLabelComponent('メールアドレス', true)}
              <Controller
                name="customer.email"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    type="email"
                    error={!!error}
                    helperText={error?.message}
                    placeholder="email@example.com"
                  />
                )}
              />
              <Typography
                color={theme.palette.neutral.greyDark}
                sx={{ ml: 1, mt: 1 }}
              >
                ※記入したメールアドレスに申し込み完了メールが届きます。
              </Typography>
            </FormControl>

            <FormControl fullWidth variant="outlined" sx={{ mb: 2 }}>
              {FormLabelComponent('住所', true)}
              <Stack spacing={2}>
                <LocationsWithPostalCode />
              </Stack>
              <Stack direction="row" alignItems="center" spacing={1} mt={1}>
                <Typography
                  color="textPrimary"
                  variant="body2"
                  fontWeight={400}
                  sx={{ mb: 1, minWidth: '145px' }}
                >
                  町名
                </Typography>
                <Controller
                  name="customer.addressLine3"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      type="text"
                      placeholder="金町"
                      error={!!error}
                      helperText={error?.message}
                      sx={{ flex: 1 }}
                    />
                  )}
                />
              </Stack>
              <Stack direction="row" alignItems="center" spacing={1} my={1}>
                <Typography
                  color="textPrimary"
                  variant="body2"
                  fontWeight={400}
                  sx={{ mb: 1, minWidth: '145px' }}
                >
                  番地
                </Typography>
                <Controller
                  name="customFields.addressLine4"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      type="text"
                      placeholder="1-2-3"
                      error={!!error}
                      helperText={error?.message}
                      sx={{ flex: 1 }}
                    />
                  )}
                />
              </Stack>
              <Stack direction="row" alignItems="center" spacing={1}>
                <Typography
                  color="textPrimary"
                  variant="body2"
                  fontWeight={400}
                  sx={{ mb: 1, minWidth: '145px' }}
                >
                  建物名称・部屋番号
                </Typography>
                <Controller
                  name="customFields.addressLine5"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      type="text"
                      placeholder=""
                      error={!!error}
                      helperText={error?.message}
                      sx={{ flex: 1 }}
                    />
                  )}
                />
              </Stack>
            </FormControl>

            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={!isValid}
              sx={{ height: { md: 60, xs: 48 }, mt: { md: 8, xs: 6 }, p: 2 }}
            >
              次へ
            </Button>
          </Stack>
        </FormProvider>
      </Box>
    </Stack>
  );
}
