import {
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';
import React from 'react';

import { TextPretty } from '@app/components/Shared/TextPretty';
import { theme } from '@app/theme';
import { Organization } from '@app/types/organization';

interface CompanyInfoSpProps {
  organization: Organization;
}

/**
 * 各情報のラベル名を表示するセルを返す
 * @param param0 - ラベル名を含むセルの内容
 * @returns 各情報のラベル名を表示するセル
 */
const TableCellWithStyle = ({ children }: { children: React.ReactNode }) => (
  <TableCell
    sx={{
      backgroundColor: theme.palette.neutral.sky,
      fontWeight: 'bold',
      width: '260px',
    }}
  >
    {children}
  </TableCell>
);

/**
 * SP用の会社情報コンポーネントを返す
 * @param param0 - 会社情報
 * @returns 会社情報コンポーネント
 */
export function CompanyInfoSp({ organization }: CompanyInfoSpProps) {
  // 表示するデータ
  const info = [
    {
      label: '会社名',
      value: organization.customFields?.companyName,
    },
    {
      label: '所在地',
      value: organization.customFields?.address,
    },
    {
      label: '設立',
      value: organization.customFields?.establishment,
    },
    {
      label: '問い合わせ',
      sub: organization.customFields?.phoneNumberSub,
      value: organization.phoneNumber,
    },
    {
      label: '定休日',
      sub: organization.customFields?.closingDaySub,
      value: organization.customFields?.closingDay,
    },
    {
      label: '資本金',
      value: organization.customFields?.capital,
    },
    {
      label: '従業員数',
      value: organization.customFields?.size
        ? `${organization.customFields.size}人`
        : '',
    },
    {
      label: '施工エリア',
      value: organization.customFields?.constructionArea,
    },
    {
      label: '施工実績',
      value: organization.customFields?.constructionResult,
    },
    {
      label: '対応可能工法',
      value: organization.customFields?.compatibleConstructionMethod,
    },
    {
      label: 'アフター・補償',
      value: organization.customFields?.compensate,
    },
    {
      label: 'ホームページ',
      value: organization.url,
    },
  ];

  return (
    <div>
      <Typography variant="h6" fontWeight={600} sx={{ mb: 3 }}>
        会社情報
      </Typography>
      <TableContainer
        component={Paper}
        sx={{ border: '1px solid #e0e0e0', borderRadius: '8px' }}
      >
        <Table>
          <TableBody>
            {info.map((item, index) => (
              <>
                <TableRow key={index}>
                  <TableCellWithStyle>{item.label}</TableCellWithStyle>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <TextPretty
                      text={item.value}
                      color={'#222222'}
                      formType="lightBase"
                    />
                    {item.sub && (
                      <TextPretty
                        text={item.sub}
                        color={theme.palette.text.secondary}
                        formType="lightXs"
                      />
                    )}
                  </TableCell>
                </TableRow>
              </>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
