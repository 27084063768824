import { Container, Typography } from '@mui/material';
import { orderBy } from 'lodash';
import { ReactElement, useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';

import { getOrganizationsByParentIds } from '@app/adapter/organization-service';
import { HomeCarousel } from '@app/components/Home/HomeCarousel';
import { ProductCardList } from '@app/components/Product/ProductCardList';
import { SearchNoResult } from '@app/components/Search/SearchNoResult';
import { BottomMenu } from '@app/components/Shared/BottomMenu';
import { HeadBlock } from '@app/components/Shared/HeadBlock';
import { Loading } from '@app/components/Shared/Loading';
import { searchResultProductsState } from '@app/domain/catalog';
import { getProducts } from '@app/domain/network-actions';
import { useDeviceType } from '@app/hooks/useBrowserHooks';
import { useSetSnackbar } from '@app/hooks/useSetSnackbar';
import { BOTTOM_MENU_ITEMS } from '@app/static/constants';
import { theme } from '@app/theme';
import {
  CategoryType,
  CategoryTypeKey,
  SearchResultProducts,
} from '@app/types/catalog';
import { LoadableState } from '@app/types/common';

export function Home(): ReactElement {
  const navigate = useNavigate();
  const { isDesktop } = useDeviceType();
  const location = useLocation();
  /**
   * NOTE: クエリパラメーターで表示を切り替えに留めるため展示場・分譲を両者保持しておく
   */
  const [resultProducts, setResultProducts] = useState<{
    [key in CategoryTypeKey]: SearchResultProducts;
  }>({
    exhibition: {
      data: [],
      nextLink: '',
      total: 0,
    },
    property: {
      data: [],
      nextLink: '',
      total: 0,
    },
  });
  const [displayProducts, setDisplayProducts] = useRecoilState(
    searchResultProductsState
  );
  const [loadable, setLoadable] = useState<LoadableState>(
    LoadableState.HAS_VALUE
  );
  const [searchType, setSearchType] = useState<CategoryTypeKey>(
    CategoryType.EXHIBITION
  );
  const setSnackbar = useSetSnackbar();
  const getSearchParams = useCallback(() => {
    const params = new URLSearchParams(location.search);
    const category = params.get('category') as CategoryTypeKey;
    const organizationId = params.get('org');
    const childrenIds = params.get('child')?.split(',') || [];
    const attributeIds = params.get('attributes')?.split(',') || [];

    const categoryKey = Object.values(CategoryType).includes(category)
      ? category
      : undefined;

    return {
      attributeIds,
      categoryKey,
      childrenIds,
      filter: organizationId ? `parentId eq '${organizationId}'` : undefined,
      locationIds: params.get('locations')
        ? [params.get('locations') as string]
        : [],
      organizationId: organizationId ? [organizationId as string] : [],
    };
  }, [location.search]);

  const updateUrlWithChildrenIds = useCallback(
    (childrenIds: string[]) => {
      const params = new URLSearchParams(location.search);
      if (childrenIds.length > 0) {
        params.set('child', childrenIds.join(','));
      } else {
        params.delete('child');
      }
      navigate(`?${params.toString()}`, { replace: true });
    },
    [location.search, navigate]
  );

  const fetchProducts = useCallback(
    async (nextLink?: string) => {
      setLoadable(LoadableState.LOADING);
      try {
        const searchParams = getSearchParams();

        let childrenIds: string[] = [];
        if (searchParams.organizationId.length > 0) {
          const parentOrganizations = await getOrganizationsByParentIds(
            searchParams.organizationId
          );
          childrenIds =
            parentOrganizations?.data?.value?.map(
              (org: { id: string }) => org.id
            ) || [];
          updateUrlWithChildrenIds(childrenIds);
        }

        const options = {
          nextLink,
          ...searchParams,
          childrenIds: childrenIds.length > 0 ? childrenIds : undefined,
          // NOTE:ホーム画面のエリアと企業で絞り込み、結果を画像と共に表示するためのexpand
          expand: 'images,organization,locations',
          limit: 10,
        };

        /**
         * 展示場のデータをフェッチしてstateにセットする
         */
        const fetchExhibitions = async () => {
          const result = await getProducts({
            ...options,
            category: CategoryType.EXHIBITION,
          });
          setResultProducts((prev) => ({
            ...prev,
            exhibition: {
              data: nextLink ? [...result.data.value] : result.data.value,
              nextLink: result.data['@nextLink'],
              total: result.data.total,
            },
          }));
        };

        /**
         * 分譲のデータをフェッチしてstateにセットする
         */
        const fetchProperties = async () => {
          const result = await getProducts({
            ...options,
            category: CategoryType.PROPERTY,
          });
          setResultProducts((prev) => ({
            ...prev,
            property: {
              data: nextLink ? [...result.data.value] : result.data.value,
              nextLink: result.data['@nextLink'],
              total: result.data.total,
            },
          }));
        };

        /**
         * CHANGED: クエリパラメーターのカテゴリー変更のたびにそのカテゴリーのデータをフェッチしていたが、カテゴリーをタイミング悪く切り替えると指定のカテゴリーとデータが不一致になることがあった。
         * そのため、両者フェッチしておきクエリパラメーターにより表示を切り替えることに留めた。
         * 表示しない方のデーターが多く初期表示に時間がかかるようであれば、Promise.allではなく指定中のカテゴリーを先にリクエストするなどして改善するなど。
         */
        await Promise.all([fetchExhibitions(), fetchProperties()]);
        setLoadable(LoadableState.HAS_VALUE);
      } catch (error) {
        setLoadable(LoadableState.HAS_ERROR);
        setSnackbar(true, 'データの取得に失敗しました');
      }
    },
    [getSearchParams, setSnackbar, updateUrlWithChildrenIds]
  );

  useEffect(() => {
    void fetchProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const category = params.get('category') as CategoryTypeKey;
    setDisplayProducts(
      category === CategoryType.PROPERTY
        ? resultProducts['property']
        : resultProducts['exhibition']
    );
  }, [location.search, resultProducts, setDisplayProducts]);

  const title =
    searchType === CategoryType.EXHIBITION ? '新着住宅展示場' : '新着分譲地';

  return (
    <>
      <HeadBlock />
      <HomeCarousel
        setSearchType={setSearchType}
        isLoading={loadable === LoadableState.LOADING}
      />
      <Container
        maxWidth="lg"
        disableGutters={!isDesktop}
        sx={{ pb: '16px!important' }}
      >
        <Typography
          sx={{
            color: theme.palette.primary.dark,
            fontSize: '14px',
            pt: 6,
            textAlign: 'center',
          }}
        >
          おすすめ情報
        </Typography>
        <Typography
          sx={{
            color: 'black',
            fontSize: '1.25rem',
            fontWeight: 'bold',
            pt: 2,
            textAlign: 'center',
          }}
        >
          {title}
        </Typography>
      </Container>
      {loadable === LoadableState.LOADING ? (
        <Loading />
      ) : loadable === LoadableState.HAS_VALUE &&
        displayProducts.data.length === 0 ? (
        <SearchNoResult />
      ) : (
        <ProductCardList
          products={orderBy(displayProducts.data, ['updatedAt'], ['desc'])}
          initialVisibleItems={6}
          isProperty={searchType !== CategoryType.EXHIBITION}
        />
      )}
      <BottomMenu menuItems={BOTTOM_MENU_ITEMS} shouldShowMenuItems={false} />
    </>
  );
}
