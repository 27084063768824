import { Box, Grid, Typography, Card, CardContent } from '@mui/material';
import React from 'react';

import { EnlargedImage } from '@app/components/Shared/EnlargedImage';
import { useDeviceType } from '@app/hooks/useBrowserHooks';
import { theme } from '@app/theme';
import {
  ProductVariant,
  ProductVariantPlotMapCategory,
} from '@app/types/catalog';

interface PropertyPlotMapProps {
  variants: ProductVariant[];
}

export function PropertyPlotMap({ variants }: PropertyPlotMapProps) {
  const { isMobile } = useDeviceType();
  return (
    <Box sx={{ mb: isMobile ? 3 : 10, mt: 3 }}>
      <Typography variant="h6" fontWeight={600} sx={{ my: 2 }}>
        区画図
      </Typography>
      <Grid container spacing={2}>
        {variants.length > 0 && (
          <>
            {variants.map((variant, index) => (
              <React.Fragment key={variant.id}>
                <Grid item xs={isMobile ? 12 : 6}>
                  {variant.images?.[0]?.url && (
                    <EnlargedImage
                      src={variant.images[0].url}
                      title={
                        Object.values(ProductVariantPlotMapCategory).find(
                          (category) =>
                            category.key === variant.customFields?.category
                        )?.label || ''
                      }
                      height="100%"
                      objectFit="contain"
                    />
                  )}
                </Grid>
                <Grid item xs={isMobile ? 12 : 6} key={variant.id}>
                  <Card
                    sx={{
                      border: `1px solid ${theme.palette.neutral.silver}`,
                      height: '100%',
                    }}
                  >
                    <CardContent>
                      <Typography
                        variant="subtitle1"
                        fontWeight={600}
                        sx={{ mb: 2 }}
                      >
                        {
                          Object.values(ProductVariantPlotMapCategory).find(
                            (category) =>
                              category.key === variant.customFields?.category
                          )?.label
                        }
                      </Typography>
                      <Typography variant="body2" sx={{ my: 2 }}>
                        {variant.description || '-'}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              </React.Fragment>
            ))}
          </>
        )}
      </Grid>
    </Box>
  );
}
