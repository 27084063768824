import {
  Box,
  Button,
  Typography,
  Card,
  CardContent,
  Divider,
} from '@mui/material';
import React, { ReactElement } from 'react';
import TagManager from 'react-gtm-module';
import { useNavigate, useParams } from 'react-router-dom';

import { useDeviceType } from '@app/hooks/useBrowserHooks';
import { GA_CUSTOM_EVENT, GA_CUSTOM_EVENT_SUFFIX } from '@app/static/constants';
import { CategoryType, CategoryTypeKey } from '@app/types/catalog';
import { generateEventName, generateEventNameSuffix } from '@app/utils/event';

function handleButtonClick(
  navigate: ReturnType<typeof useNavigate>,
  productId: string | undefined,
  isExhibition: boolean
) {
  const path = isExhibition
    ? '/exhibitions/:productId/inquiry/create'
    : '/properties/:productId/inquiry/create';
  if (productId) {
    navigate(path.replace(':productId', productId));
  }
}

function sendGAEvent(eventName: string, isMobile: boolean, suffix?: string) {
  const eventNameWithSuffix = suffix
    ? generateEventNameSuffix(generateEventName(eventName, isMobile), suffix)
    : generateEventName(eventName, isMobile);
  TagManager.dataLayer({
    dataLayer: {
      event: eventNameWithSuffix,
    },
  });
}

interface InquiryCardsVerticalProps {
  isExhibition: boolean;
  organizationId?: string;
  parentOrganizationId: string;
}

export function InquiryCardsVertical({
  isExhibition,
  parentOrganizationId,
}: InquiryCardsVerticalProps): ReactElement {
  const navigate = useNavigate();
  const { productId } = useParams<{ productId: string }>();
  const { isMobile } = useDeviceType();

  return (
    <Box
      sx={{
        maxWidth: isMobile ? '100vw' : 300,
        ml: isMobile ? 0 : 3,
        width: '100%',
      }}
    >
      <Card sx={{ border: '1px solid #ddd', borderRadius: '8px', mb: 2 }}>
        <CardContent>
          <Typography variant="body1" fontWeight={600}>
            {isExhibition ? 'この住宅展示場を見学' : 'この物件を見学'}
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            sx={{ mb: 2, mt: 1 }}
          >
            専門スタッフがご対応。最新の空間を体験しながら住まいづくりのプロに出会えます。
          </Typography>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={() => {
              handleButtonClick(navigate, productId, isExhibition);
              //Send GA Event
              let eventName = GA_CUSTOM_EVENT.PROPERTIES_TOUR_RESERVATION;
              if (isExhibition) {
                eventName = GA_CUSTOM_EVENT.EXHIBITION_TOUR_RESERVATION;
              }
              sendGAEvent(eventName, isMobile, GA_CUSTOM_EVENT_SUFFIX.RIGHT);
            }}
          >
            見学を予約する
          </Button>
        </CardContent>

        <Divider sx={{ mt: 2, mx: 2 }} />

        <CardContent>
          <Typography variant="body1" fontWeight={600}>
            住宅カタログを無料でお届け
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            sx={{ mb: 2, mt: 1 }}
          >
            いくつもの商品ラインナップを1冊の総合カタログでまとめて紹介しています。家づくりの最新情報を知るのに打ってつけです。
          </Typography>
          <Button
            variant="outlined"
            color="primary"
            fullWidth
            onClick={() => {
              if (parentOrganizationId && productId) {
                const path = isExhibition
                  ? `/companies/${parentOrganizationId}/inquiry/create?category=${CategoryType.EXHIBITION}&productId=${productId}`
                  : `/companies/${parentOrganizationId}/inquiry/create?category=${CategoryType.PROPERTY}&productId=${productId}`;
                navigate(path);

                if (isExhibition) {
                  const eventName = GA_CUSTOM_EVENT.EXHIBITION_DOCUMENT_REQUEST;
                  sendGAEvent(eventName, isMobile);
                } else {
                  const eventName = GA_CUSTOM_EVENT.PROPERTIES_DOCUMENT_REQUEST;
                  sendGAEvent(
                    eventName,
                    isMobile,
                    GA_CUSTOM_EVENT_SUFFIX.RIGHT
                  );
                }
              }
            }}
          >
            資料を請求する
          </Button>
        </CardContent>
      </Card>
    </Box>
  );
}

export interface InquiryCardsHorizontalProps {
  isExhibition: boolean;
  organizationId?: string;
  parentOrganizationId: string;
  type: CategoryTypeKey;
}

export function InquiryCardsHorizontal({
  type,
  isExhibition,
  parentOrganizationId,
}: InquiryCardsHorizontalProps): ReactElement {
  const navigate = useNavigate();
  const { productId } = useParams<{ productId: string }>();
  const { isMobile } = useDeviceType();

  return (
    <Box
      sx={{ display: 'flex', justifyContent: 'center', mt: 3, width: '100%' }}
    >
      <Card
        sx={{
          border: '1px solid #ddd',
          borderRadius: '8px',
          maxWidth: '900px',
          mb: 5,
          p: 3,
          width: '100%',
        }}
      >
        <CardContent
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'center',
          }}
        >
          <Typography variant="body1" fontWeight={600} sx={{ mb: 2 }}>
            {isExhibition ? 'この住宅展示場を見学' : 'この物件を見学'}
          </Typography>
          <Typography variant="body2" color="textSecondary" sx={{ mb: 3 }}>
            専門スタッフがご対応。最新の空間を体験しながら住まいづくりのプロに出会えます。
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: isMobile ? 'column' : 'row',
              gap: 2,
              width: isMobile ? '100%' : undefined,
            }}
          >
            {type === CategoryType.PROPERTY && (
              <Button
                variant="outlined"
                color="primary"
                sx={{ width: isMobile ? '100%' : '200px' }}
                onClick={() => {
                  if (parentOrganizationId && productId) {
                    navigate(
                      `/companies/${parentOrganizationId}/inquiry/create?category=${CategoryType.PROPERTY}&productId=${productId}`
                    );
                    sendGAEvent(
                      GA_CUSTOM_EVENT.PROPERTIES_DOCUMENT_REQUEST,
                      isMobile,
                      GA_CUSTOM_EVENT_SUFFIX.CENTER
                    );
                  }
                }}
              >
                資料を請求する
              </Button>
            )}
            <Button
              variant="contained"
              color="primary"
              sx={{ width: isMobile ? '100%' : '200px' }}
              onClick={() => {
                handleButtonClick(navigate, productId, isExhibition);
                //Send GA Event
                let eventName = GA_CUSTOM_EVENT.PROPERTIES_TOUR_RESERVATION;
                if (isExhibition) {
                  eventName = GA_CUSTOM_EVENT.EXHIBITION_TOUR_RESERVATION;
                }
                sendGAEvent(eventName, isMobile, GA_CUSTOM_EVENT_SUFFIX.CENTER);
              }}
            >
              見学を予約する
            </Button>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
}
