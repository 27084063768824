import {
  Box,
  Grid,
  Typography,
  Card,
  CardContent,
  Divider,
} from '@mui/material';
import React from 'react';

import { EnlargedImage } from '@app/components/Shared/EnlargedImage';
import { useDeviceType } from '@app/hooks/useBrowserHooks';
import { theme } from '@app/theme';
import {
  ProductVariant,
  ProductVariantAroundCategory,
  ProductVariantEquipmentCategory,
  ProductVariantExteriorCategory,
  ProductVariantInteriorCategory,
  ProductVariantSku,
} from '@app/types/catalog';

interface PropertyVariantSkuDetailsProps {
  variants: ProductVariant[];
}

const renderVariantSection = (
  title: string,
  variants: ProductVariant[],
  isMobile: boolean,
  category: {
    [key: string]: {
      key: string;
      label: string;
    };
  }
): React.ReactNode => {
  if (variants.length === 0) {
    return null;
  }

  return (
    <>
      <Divider sx={{ my: 4 }} />
      <Typography variant="h6" fontWeight={600} sx={{ my: 2 }}>
        {title}
      </Typography>
      <Grid container spacing={2} sx={{ mb: 7 }}>
        {variants.map((variant) => (
          <Grid item xs={isMobile ? 12 : 6} key={variant.id}>
            <Card
              sx={{
                border: `1px solid ${theme.palette.neutral.silver}`,
                height: '100%',
              }}
            >
              {variant.images?.[0]?.url && (
                <EnlargedImage
                  src={variant.images[0].url}
                  title={
                    variant.sku === ProductVariantSku.EQUIPMENT
                      ? variant.title
                      : Object.values(category).find(
                          (category) =>
                            category.key === variant.customFields?.category
                        )?.label || ''
                  }
                  mt={3}
                  objectFit="contain"
                />
              )}
              <CardContent>
                <Typography variant="subtitle1" fontWeight={600} sx={{ my: 2 }}>
                  {variant.sku === ProductVariantSku.EQUIPMENT
                    ? variant.title
                    : Object.values(category).find(
                        (category) =>
                          category.key === variant.customFields?.category
                      )?.label}
                </Typography>
                <Typography variant="body2" sx={{ my: 2 }}>
                  {variant.description}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export function PropertyVariantSkuDetails({
  variants,
}: PropertyVariantSkuDetailsProps) {
  const exteriorVariants = variants.filter(
    (variant) => variant.sku === ProductVariantSku.EXTERIOR
  );
  const aroundVariants = variants.filter(
    (variant) => variant.sku === ProductVariantSku.AROUND
  );
  const equipmentVariants = variants.filter(
    (variant) => variant.sku === ProductVariantSku.EQUIPMENT
  );
  const interiorVariants = variants.filter(
    (variant) => variant.sku === ProductVariantSku.INTERIOR
  );
  const { isMobile } = useDeviceType();

  return (
    <Box sx={{ mt: 3 }}>
      {renderVariantSection(
        '外観',
        exteriorVariants,
        isMobile,
        ProductVariantExteriorCategory
      )}
      {renderVariantSection(
        '内観',
        interiorVariants,
        isMobile,
        ProductVariantInteriorCategory
      )}
      {renderVariantSection(
        '設備',
        equipmentVariants,
        isMobile,
        ProductVariantEquipmentCategory
      )}
      {renderVariantSection(
        '周辺環境',
        aroundVariants,
        isMobile,
        ProductVariantAroundCategory
      )}
    </Box>
  );
}
